<template>
  <b-row class="user-edit-address-card">
    <b-col
      v-for="address in addressInfo"
      :key="address.id"
      cols="12"
      md="4"
    >
      <render-address
        :address="address"
        @edit-address="openAddressModal"
        @delete-address="deleteAddressInfo"
      />
    </b-col>
    <b-col
      cols="12"
      md="4"
      class="cursor-pointer"
      @click="addNewAddress"
    >
      <b-card
        no-body
        class="box-shadow-card"
        style="height: 90%;"
      >
        <b-card-body style="display: grid;place-content: center;">
          <p class="font-20">
            {{ $t('Add new address') }} <feather-icon icon="PlusIcon" />
          </p>
        </b-card-body>
      </b-card>
    </b-col>
    <b-modal
      v-model="toggleEditModal"
      :no-close-on-backdrop="true"
      :title="$t('Address Details')"
      :ok-title="$t('Save')"
      ok-only
      hide-footer
      size="lg"
      @ok="submitAddress"
    >
      <address-modal
        ref="addressModal"
        @address-selected="setPlace"
        @set-address="setAdress"
        @save-details="() => {
          toggleEditModal = false
          submitAddress()
        }"
      />
    </b-modal>
  </b-row>
</template>

<script>
import { BCard, BCardBody, BRow, BCol, BModal } from 'bootstrap-vue'
import AddressModal from './AddressModal.vue'
import RenderAddress from './RenderAddress.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BModal,
    AddressModal,
    RenderAddress,
  },
  props: {
    addressInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      toggleEditModal: false,
      editAddress: {},
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
      addressTypeInfo: [
        {
          label: 'Home',
          value: 'Home',
        },
        {
          label: 'Work',
          value: 'Work',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    }
  },
  methods: {
    setPlace(place) {
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.editAddress.map_address = place.formatted_address
        this.editAddress.latitude = marker.lat
        this.editAddress.longitude = marker.lng
        this.markers = {
          position: marker,
        }
        this.center = marker
      }
    },
    openAddressModal(address) {
      this.editAddress = address
      this.toggleEditModal = true
      setTimeout(() => {
        this.$refs.addressModal.$data.editAddress = address
      }, 250)
    },
    addNewAddress() {
      this.editAddress = {
        map_address: '',
        flat_no: '',
        street: '',
        address_type: '',
        id: 0,
        building: '',
        zipcode: '',
      }
      this.toggleEditModal = true
    },
    submitAddress() {
      this.$emit('add-new-address', this.editAddress)
    },
    setAdress(place) {
      this.editAddress = JSON.parse(JSON.stringify(place))
    },
    deleteAddressInfo(address) {
      this.$swal({
        title: this.$t('Please confirm you want to delete this address?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('delete-address', address.id)
        }
      })
    },
  },
}
</script>

<style scoped>
.card-address-info {
  background-image: url('~@/assets/images/app-images/notifications_bg.png');
  background-size: cover;
  border: 0;
}
</style>

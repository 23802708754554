<template>
  <b-modal
    id="stripePaymentModalSub"
    v-model="showPaymentModal"
    :no-close-on-backdrop="true"
    :title="$t('Pay via Stripe')"
    hide-footer
    @hidden="paymentCancelled"
    size="xl"
  >
    <div class="make-stripe-payment">
      <form id="payment-form" @submit.prevent="capturePayment">
        <div id="payment-element"> </div>
        <div class="text-center mt-2 d-flex align-items-end" style="height: 140px">
          <button class="btn btn-danger mr-2" @click="clearForm" :disabled="cardConfigs.disabledButton">
            {{ $t('Cancel') }}
          </button>
          <button class="btn btn-primary" type="submit" id="submit" :disabled="cardConfigs.disabledButton">
            {{ $t('Pay Now') }}
          </button>
        </div>
        <div id="payment-message" class="hidden"></div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'

export default {
  data() {
    return {
      showPaymentModal: false,
      cardConfigs: {
        disabledButton: false,
        errorText: '',
      },
      stripePublicKey: null,
      stripeInfo: {
        card: null,
        stripe: null,
        elements: null,
        data: null,
      },
    }
  },
  methods: {
    resetCard() {
      this.cardConfigs = {
        disabledButton: false,
        errorText: '',
      }
    },
    paymentCancelled($event) {
      this.$emit('form-cancelled', $event)
    },
    clearForm() {
      this.showPaymentModal = false
      this.$emit('form-cancelled')
    },
    async initStripeKey(stripeKey, clientSecret) {
      showLoader()
      this.stripePublicKey = stripeKey
      this.stripeInfo.stripe = window.Stripe(this.stripePublicKey)
      this.showPaymentModal = true

      setTimeout(() => {
        const appearance = {
          theme: 'stripe'
        };
        this.stripeInfo.elements = this.stripeInfo.stripe.elements({ clientSecret, appearance });
        const paymentElementOptions = {
          layout: "accordion",
        };
        this.cardConfigs.disabledButton = false
        const paymentElement = this.stripeInfo.elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      }, 500);
      hideLoader()
    },
    async capturePayment(e) {
      e.preventDefault();
      this.cardConfigs.disabledButton = true
      showLoader()
      const { error } = await this.stripeInfo.stripe.confirmPayment({
        elements: this.stripeInfo.elements,
        confirmParams: {
          return_url: `${window.location.href}`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        showErrorNotification(this, error.message);
      } else {
        showErrorNotification(this, "An unexpected error occurred.");
      }
      this.clearForm()
      hideLoader()
    },
  },
}
</script>


<template>
    <div>
        
    </div>
</template>

<script>
import { isEmpty } from '@/utilities'

export default {
    name: "UserSubscriptionUsage",
    props: {
        activeSubscription: {
            type: Object,
            required: true
        }
    },
    methods: {
        isEmpty
    }
}
</script>
<template>
  <b-row class="user-edit-payment-method">
    <b-col
      v-for="(card, index) in userCards"
      :key="index"
      cols="12"
      md="4"
    >
      <b-card
        no-body
        class="payment-card-info"
      >
        <b-card-header class="pb-1 d-flex align-items-center justify-content-between">
          <b-badge
            pill
            variant="light-primary text-white"
            style="color: #fff !important"
          >
            {{ $t('Credit Card') }}
          </b-badge>
          <img
            style="width: 60px"
            :src="require('@/assets/images/app-icons/user/visa_icon2x.png')"
          >
        </b-card-header>
        <b-card-body>
          <h5 class="apply-job-title text-white">
            XXXX XXXX XXXX {{ card.last_four }}
          </h5>
          <b-card-text class="mb-2 d-flex align-items-center justify-content-between">
            <p class="m-0 mt-2 text-white">
              {{ card.holder_name }}
            </p>
            <p class="m-0 mt-2 text-white">
              {{ card.month }}/{{ card.year }}
            </p>
          </b-card-text>

          <div class="d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mx-1 text-white"
              @click="deletePaymentMethod(card)"
            >
              {{ $t('Delete') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="4"
      class="cursor-pointer"
      @click="addNewPaymentMethod"
    >
      <b-card
        no-body
        class="payment-card-info"
        style="height: 90%"
      >
        <b-card-body style="display: grid; place-content: center">
          <p class="font-20 text-white">
            {{ $t('Add new card') }} <feather-icon icon="PlusIcon" />
          </p>
        </b-card-body>
      </b-card>
    </b-col>
    <b-modal
      v-model="toggleEditModal"
      :title="$t('Add Card')"
      :ok-title="$t('Save')"
      :no-close-on-backdrop="true"
      hide-footer
      size="lg"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div id="number" />
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-right"
        >
          <button
            class="btn btn-primary"
            @click="submitPaymentMethod"
          >
            {{ $t('Save') }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import { BCard, BCardHeader, BBadge, BCardText, BCardBody, BButton, BRow, BCol, BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../useUser'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      toggleEditModal: false,
      addPaymentMethodInfo: {},
      userCards: [],
      stripeInfo: {
        stripe: null,
        cardNumber: null,
        elements: null,
      },
    }
  },
  mounted() {
    if (!window.stripeInfo) {
      window.stripeInfo = {}
    }
    this.getUserCardList()
    this.initStripeMethods()
  },
  destroyed() {
    window.stripeInfo = {}
  },
  methods: {
    initStripeMethods() {
      window.stripeInfo.stripe = window.Stripe('pk_test_51IsFNnJgVyJHcfTImh51bTVM4iHrFT5xZuV6CibD3y8t3RRgexPcV33ZdCIB6Q6wSp96XNaRy1zojpl7ywaeuCEr00hcPbqcam')
    },
    makeCards() {
      const style = {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          lineHeight: '40px',
          fontWeight: 300,
          fontFamily: 'Helvetica Neue',
          fontSize: '15px',

          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      }
      window.stripeInfo.elements = window.stripeInfo.stripe.elements()
      window.stripeInfo.cardNumber = window.stripeInfo.elements.create('card', { style })
      window.stripeInfo.cardNumber.mount('#number')
    },
    getStripeToken() {
      showLoader()
      this.toggleEditModal = true
      window.stripeInfo.stripe
        .createToken(window.stripeInfo.cardNumber)
        .then(response => {
          if (response.error) {
            hideLoader()
            if (response.error.message) showDangerNotification(this, response.error.message)
            if (response.message) showDangerNotification(this, response.message)
          } else {
            const formData = new FormData()
            formData.append('stripe_token', response.token.id)

            const { addUserCard } = useUserUi()
            addUserCard(formData)
              .then(({ data }) => {
                if (data.statusCode === '200') {
                  this.toggleEditModal = false
                  showSuccessNotification(this, data.message)
                  this.getUserCardList()
                } else {
                  showDangerNotification(this, data.message)
                }
                hideLoader()
              })
              .catch(error => {
                showErrorNotification(this, error)
              })
          }
        })
        .catch(err => {
          hideLoader()
          console.log(err)
        })
    },
    getUserCardList() {
      const { userCardList } = useUserUi()
      userCardList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userCards = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    addNewPaymentMethod() {
      this.addPaymentMethodInfo = {
        fullName: '',
        cardNumber: '',
        month: '',
        year: '',
        cvv: '',
        id: 0,
      }
      this.toggleEditModal = true
      showLoader()
      setTimeout(() => {
        hideLoader()
        this.makeCards()
      }, 2000)
    },
    submitPaymentMethod() {
      this.getStripeToken()
    },
    deletePaymentMethod(props) {
      this.$swal({
        title: this.$t('You want to delete this payment method?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { userDeletePaymentMethod } = useUserUi()
          userDeletePaymentMethod(props.id)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                showSuccessNotification(this, data.message)
                this.getUserCardList()
              } else {
                showDangerNotification(this, data.message)
              }
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.payment-card-info {
  background-image: url('~@/assets/images/app-images/notifications_bg.png');
  background-size: cover;
  border: 0;
}
</style>

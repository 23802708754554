<template>
  <div class="user-edit-tab-account">
    <!-- Media -->
    <b-row v-if="userData && false">
      <b-col
        cols="12"
        md="3"
      >
        <b-card
          no-body
          class="card-wallet-info"
        >
          <b-card-header class="pb-1">
            <h5 class="mb-0">
              {{ $t('Wallet Balance') }}
            </h5>
          </b-card-header>
          <b-card-body> {{ userData.currency_symbol }} {{ userData.wallet_balance }} </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- User Info: Input Fields -->
    <validation-observer ref="profileValidation">
    <b-form @submit.prevent="updateUserProfile">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('First Name')"
            label-for="firstName"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('First Name')"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="userData.first_name"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Last Name')"
            label-for="lastName"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Last Name')"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="userData.last_name"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="userData.email"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Mobile')"
            label-for="mobile"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required"
            >
              <VuePhoneNumberInput
                ref="countryCodeRef"
                v-model="userData.mobile"
                placeholder="+91 0000000000"
                @update="onUpdate"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Country')"
            label-for="country"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Country')"
              rules="required"
            >
              <model-select
                v-model="userData.country_id"
                :options="countryInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('State')"
            label-for="state"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('State')"
              rules="required"
            >
              <model-select
                v-model="userData.state_id"
                :options="stateInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('City')"
            label-for="city"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('City')"
              rules="required"
            >
              <model-select
                v-model="userData.city_id"
                :options="cityInfo.map(x => ({
                  text: x.label,
                  value: x.value,
                }))"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Language')">
            <b-form-select
              v-model="userData.language"
              :options="$superApp.appConstants.languageList()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              class="ml-0"
              :default-image="userData.picture"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        type="submit"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('Save Changes') }}
      </b-button>
    </b-form>
    </validation-observer>
    <!-- Action Buttons -->
  </div>
</template>

<script>
import { BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardBody, BFormSelect } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import VuePhoneNumberInput from 'vue-phone-number-input'
import eventBus from '@/libs/event-bus'
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../useUser'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageInput from '../../users/shared/ImageInput.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BFormSelect,
    ModelSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    VuePhoneNumberInput,
    ValidationObserver,
    ValidationProvider,
    ImageInput
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
    countryInfo: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.picture = base64
    })

    return {
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      phoneNumberPayload: null,
      cityInfo: [],
      stateInfo: [],
    }
  },
  watch: {
    'userData.country_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.userData && this.userData.country_id) {
          this.onCountryChange()
        }
      },
    },
    'userData.state_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.userData && this.userData.state_id) {
          this.onStateChange()
        }
      },
    },
  },
  methods: {
    onStateChange() {
      this.cityInfo = []
      const { getCityByStateCountry } = useUserUi()
      showLoader()
      getCityByStateCountry(`country_id=${this.userData.country_id}&state_id=${this.userData.state_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cityInfo = data.responseData.map(x => ({
              label: x.city_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.userData.city_id)) {
              this.userData.city_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      if (!this.userData.country_id) return
      this.cityInfo = []
      this.stateInfo = []
      const { getStateByCountry } = useUserUi()
      showLoader()
      getStateByCountry(this.userData.country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.stateInfo = data.responseData.map(x => ({
              label: x.state_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.userData.state_id)) {
              this.userData.state_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          if (this.userData.state_id) {
            this.onStateChange()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    updateUserProfile() {
      this.$refs.profileValidation.validate().then(success => {
        if (success) {
          const { updateUserProfileInfo } = useUserUi()
          if (this.phoneNumberPayload) {
            this.userData.country_code = this.phoneNumberPayload.countryCallingCode
          }
          const updateFieldList = ['first_name', 'last_name', 'email', 'mobile', 'country_id', 'state_id', 'city_id', 'language', 'country_code']
          const formData = new FormData()
          if (document.querySelector('.user-profile-image') && document.querySelector('.user-profile-image').files && document.querySelector('.user-profile-image').files.length > 0) {
            formData.append('picture', document.querySelector('.user-profile-image').files[0])
          }else{
            formData.append('picture', this.userData.picture)
          }
          updateFieldList.map(x => formData.append(x, this.userData[x]))
          showLoader()
          updateUserProfileInfo(formData)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                window.SuperApp.getters.setUserInfo(JSON.stringify(data.responseData))
                eventBus.$emit('set-user-info-force')
                showSuccessNotification(this, data.message)
              } else {
                showDangerNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    inputFile(payload) {
      if (this.userData) this.userData.picture = payload
    },
  },
}
</script>

<template>
  <b-form class="user-edit-change-password">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('Old Password')"
          label-for="oldPasword"
        >
          <b-input-group>
            <b-form-input
              v-model="passwordInfo.old_password"
              :type="passwordType.old_password"
              placeholder="Enter your old password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon.old_password"
                class="cursor-pointer"
                @click="togglePasswordVisibility('old_password')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('New Password')"
          label-for="newPassword"
        >
          <b-input-group>
            <b-form-input
              v-model="passwordInfo.password"
              :type="passwordType.password"
              placeholder="Enter your new password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon.password"
                class="cursor-pointer"
                @click="togglePasswordVisibility('password')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('Confirm Password')"
          label-for="confirmNewPassword"
        >
          <b-input-group>
            <b-form-input
              v-model="passwordInfo.password_confirmation"
              :type="passwordType.password_confirmation"
              placeholder="Enter your conform password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon.password_confirmation"
                class="cursor-pointer"
                @click="togglePasswordVisibility('password_confirmation')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="changePassword"
        >
          {{ $t('Change Password') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="resetFields"
        >
          {{ $t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../useUser'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      passwordInfo: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      passwordType: {
        old_password: 'password',
        password: 'password',
        password_confirmation: 'password',
      },
      passwordToggleIcon: {
        old_password: 'EyeIcon',
        password: 'EyeIcon',
        password_confirmation: 'EyeIcon',
      },
    }
  },
  methods: {
    togglePasswordVisibility(key) {
      if (this.passwordType[key] === 'password') {
        this.passwordType[key] = 'text'
        this.passwordToggleIcon[key] = 'EyeOffIcon'
      } else {
        this.passwordType[key] = 'password'
        this.passwordToggleIcon[key] = 'EyeIcon'
      }
    },
    resetFields() {
      Object.keys(this.passwordInfo).forEach(x => {
        this.passwordInfo[x] = ''
      })
    },
    changePassword() {
      if (Object.keys(this.passwordInfo).filter(x => !this.passwordInfo[x]).length > 0) {
        showDangerNotification(this, 'Please enter all info')
        // eslint-disable-next-line no-useless-return
        return
      }
      const formData = new FormData()
      Object.keys(this.passwordInfo).forEach(x => {
        formData.append(x, this.passwordInfo[x])
      })
      showLoader()
      const { userChangePassword } = useUserUi()
      userChangePassword(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.resetFields()
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
.user-edit-change-password {
  .input-group-append {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d6de;

    .input-group-text {
      height: 36px;
    }
  }
}
</style>

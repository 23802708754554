<template>
    <div>
        <ul v-if="!isEmpty(activeSubscription) && !isEmpty(activeSubscription.plan) && activeSubscription.plan.tenure_type == 'MONTH'"
            class="list-unstyled mt-1">
            <li>
                <b-progress animated :value="activeSubscription.daysRemainingPer"
                    :variant="activeSubscription.daysRemaining < 10 ? `danger` : `success`"
                    :class="`progress-bar-${activeSubscription.daysRemaining < 10 ? 'danger' : 'success'}`" />
                <span class="align-middle">{{ activeSubscription.daysRemaining }} days remaining.
                </span>
            </li>
            <li class="mt-3">
                <b-progress animated :value="activeSubscription.remainingOrdersPer"
                    :variant="activeSubscription.remainingOrders < 10 ? `danger` : `success`"
                    :class="`progress-bar-${activeSubscription.remainingOrders < 10 ? 'danger' : 'success'}`" />
                <span class="align-middle">{{ activeSubscription.remainingOrders }} orders
                    remaining (used {{ activeSubscription.current_month_orders }} of {{
                        activeSubscription.plan.allowed_orders }} orders).
                    <div class="text-muted">
                    </div>
                </span>
            </li>
        </ul>
        <ul v-if="!isEmpty(activeSubscription) && !isEmpty(activeSubscription.plan) && activeSubscription.plan.tenure_type == 'LIFETIME'"
            class="list-unstyled mt-1">
            <li class="mt-3">
                <b-progress animated :value="activeSubscription.remainingOrdersPer"
                    :variant="activeSubscription.remainingOrders < 10 ? `danger` : `success`"
                    :class="`progress-bar-${activeSubscription.remainingOrders < 10 ? 'danger' : 'success'}`" />
                <span class="align-middle">{{ activeSubscription.remainingOrders }} orders
                    remaining (used {{ activeSubscription.current_month_orders }} of {{
                        activeSubscription.plan.allowed_orders }} orders).
                    <div class="text-muted">
                    </div>
                </span>
            </li>
        </ul>
        <ul v-if="!isEmpty(activeSubscription) && !isEmpty(activeSubscription.plan) && activeSubscription.plan.tenure_type == 'YEAR'"
            class="list-unstyled mt-1">
            <li>
                <b-progress animated :value="activeSubscription.daysRemainingPer"
                    :variant="activeSubscription.daysRemaining < 30 ? `danger` : `success`"
                    :class="`progress-bar-${activeSubscription.daysRemaining < 10 ? 'danger' : 'success'}`" />
                <span class="align-middle">{{ activeSubscription.daysRemaining }} days remaining.
                </span>
            </li>
            <li class="mt-3">
                <b-progress animated :value="activeSubscription.remainingOrdersPer"
                    :variant="activeSubscription.remainingOrders < 30 ? `danger` : `success`"
                    :class="`progress-bar-${activeSubscription.remainingOrders < 10 ? 'danger' : 'success'}`" />
                <span class="align-middle">{{ activeSubscription.remainingOrders }} orders
                    remaining (used {{ activeSubscription.current_month_orders }} of {{
                        activeSubscription.plan.allowed_orders }} orders).
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { isEmpty } from '@/utilities'

export default {
    name: "StoreProviderSubscriptionUsage",
    props: {
        activeSubscription: {
            type: Object,
            required: true
        }
    },
    methods: {
        isEmpty
    }
}
</script>